import _, { get } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';

export default function DetailItem({ item }) {
  const router = useRouter();
  const handleClickCountry = (country) => {
    router.push(`/destination/${country.toLowerCase().split(' ').join('-')}`);
  }
  const handleClickRegion = (region) => {
    const country = get(item, 'value.country_name').toLowerCase().split(' ').join('-');
    router.push(
      `/destination/${country}/${region.toLowerCase().split(' ').join('-')}`
    );
  }
  const handleClickCity = (des) => {
    if (des.region !== null) {
      const country = des.country.toLowerCase().split(' ').join('-');
      const region = des.region;
      const city = des.city;
      router.push(`/destination/${country}/${region}/${city}`, undefined);
    }
    else {
      const country = des.country.toLowerCase().split(' ').join('-');
      const city = des.city;
      router.push(`/destination/${country}/${city}`);
    }
  }

  const handleRenderDetail = (destination) => {
    switch (destination.type) {
      case 'with_region':
        return (
          <>
            <div className="col-6 explore-region">
              <span onClick={() => handleClickRegion(destination.value.region)}>
                {destination.value.region}
              </span>
            </div>
            <div className="col explore-city">
              {_.map(
                get(destination, 'value.destination', []),
                (des, index) => (
                  <span
                    onClick={() => handleClickCity(des)}
                    key={index}
                  >
                    {get(des, 'city')?.split('-').join(' ')}
                  </span>
                )
              )}
            </div>
          </>
        );
      case 'without_region':
        return (
          <>
            <div className="col explore-city">
              {_.map(
                get(destination, 'value.destination', []),
                (des, index) => (
                  <span key={index} onClick={() => handleClickCity(des)}>
                    {get(des, 'city')?.split('-').join(' ')}
                  </span>
                )
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div className="row explore-container__content__body__detail">
      <div className="col-3 explore-country">
        <h4 onClick={() => handleClickCountry(get(item, 'value.country_name'))}>
          {get(item, 'value.country_name')}
        </h4>
      </div>
      <div className="col">
        {_.map(get(item, 'value.destinations', []), (destination, index) => (
          <div className="row mb-2" key={index}>
            {handleRenderDetail(destination)}
          </div>
        ))}
      </div>
    </div>
  );
}
