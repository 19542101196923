import { get } from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

export default function CityItem({ item }) {
  const router = useRouter();
  const city = get(item, 'City.city').split('-').join(' ');
  const handleClickCity = (item) => {
    if (item.City.region !== null) {
      const contry = item.Country.toLowerCase().split(' ').join('-');
      const region = item.City.region;
      const city = item.City.city;
      router.push(`/destination/${contry}/${region}/${city}`, undefined);
    } else {
      const contry = item.Country.toLowerCase().split(' ').join('-');
      const city = item.City.city;
      router.push(`/destination/${contry}/${city}`);
    }
  };
  return (
    <div
      onClick={() => handleClickCity(item)}
      className="explore-container__content__item"
    >
      <Image
        src={
          get(item, 'CoverImage')
        }
        layout="fill"
        objectFit="cover"
      />
      <h4>{city}</h4>
      <span>{get(item, 'Country')}</span>
    </div>
  );
}
