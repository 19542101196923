import React, { useCallback, useEffect, useState } from 'react';
import CityItem from './CityItem';
import DetailItem from './DetailItem';
import { getExplorerNav } from 'services';
import _, { get } from 'lodash';
import { useRouter } from 'next/router';

export default function Explore() {
  const [data, setData] = useState([]);
  const [activeRegion, setActiveRegion] = useState('');
  const [dataMobile, setDataMobile] = useState([]);
  const router = useRouter();
  const handlClickCountry = (country) => {
    const slug = country.toLowerCase().split(' ').join('-');
    router.push(`/destination/${slug}`);
  };

  const handleFilterData = useCallback(
    (type) => {
      return data.filter((item) => item.type === type);
    },
    [data]
  );

  useEffect(() => {
    getExplorerNav()
      .then((res) => res.data)
      .then((res) => {
        setData(get(res, 'data.body', []));
        const firstRegion = res.data.body.find(
          (item) => item.type === 'region'
        );
        setActiveRegion(get(firstRegion, 'value.name'));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const country = data
      .find(
        (item) => item.type === 'region' && item.value.name === activeRegion
      )
      ?.value?.country.map((item) => item.country);
    if (!country) return;
    const dataMobile = data.filter(
      (item) =>
        item.type === 'destination_page' &&
        country.includes(item.value.country_name)
    );
    console.log('dataMobile', dataMobile);
    setDataMobile(dataMobile);
  }, [activeRegion]);
  return (
    <div className="explore-container">
      <div className="explore-container__toc">
        <h4>Popular Destinations</h4>
        {_.map(handleFilterData('region'), (item, index) => (
          <div key={index} className="explore-container__toc__item">
            <h4>{get(item, 'value.name')}</h4>
            <div className="text-description">
              {_.map(get(item, 'value.country', []), (country) => (
                <span
                  onClick={() => handlClickCountry(get(country, 'country'))}
                >
                  {get(country, 'country')}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="explore-container__content">
        <div className="explore-container__content__head">
          {_.map(
            get(handleFilterData('standard_city'), '[0].value.cities', []),
            (item, index) => (
              <CityItem key={index} item={item} />
            )
          )}
        </div>
        <div className="explore-container__content__body">
          {_.map(handleFilterData('destination_page'), (item, index) => (
            <DetailItem key={index} item={item} />
          ))}
        </div>
        <div className="explore-container__content__mobile__region">
          {_.map(handleFilterData('region'), (item, index) => (
            <div
              key={index}
              className={activeRegion === item.value.name ? 'active' : ''}
              onClick={() => setActiveRegion(item.value.name)}
            >
              <span>{item.value.name}</span>
              <div></div>
            </div>
          ))}
        </div>
        <div className="explore-container__content__mobile__items">
          {_.map(dataMobile, (item, index) => (
            <div
              key={index}
              className="explore-container__content__mobile__item"
            >
              <h6 onClick={() => handlClickCountry(item.value.country_name)}>
                {item.value.country_name}
              </h6>
              <div>
                {_.map(get(item, 'value.destinations', []), (des, index) => (
                  <DestinationMobile
                    key={index}
                    data={des}
                    country={item.value.country_name}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const DestinationMobile = ({ data, country }) => {
  const router = useRouter();
  const handleClickRegion = (region) => {
    const country_slug = country.toLowerCase().split(' ').join('-');
    router.push(`/destination/${country_slug}/${region}`);
  };
  const handleClickCity = (des) => {
    const country_slug = country.toLowerCase().split(' ').join('-');
    if (des.region !== null) {
      const region = des.region.toLowerCase().split(' ').join('-');
      router.push(`/destination/${country_slug}/${region}/${des.city}`);
    } else {
      router.push(`/destination/${country_slug}/${des.city}`);
    }
  };
  const handleRenderData = (data) => {
    if (data.value?.region) {
      return (
        <div className='explore-mobile-item-region'>
          <h6 onClick={() => handleClickRegion(data.value?.region)}>
            {data.value?.region}
          </h6>
          <div className="explore-mobile-item">
            {_.map(data.value?.destination, (des, index) => (
              <>
                {get(des, 'city') && (
                  <div
                    onClick={() => handleClickCity(des)}
                    className="explore-mobile-item-city"
                    key={index}
                  >
                    {get(des, 'city')?.split('-').join(' ')}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="explore-mobile-item">
          {_.map(data.value?.destination, (des, index) => (
            <>
              {get(des, 'city') && (
                <div
                  onClick={() => handleClickCity(des)}
                  className="explore-mobile-item-city"
                  key={index}
                >
                  {get(des, 'city')?.split('-').join(' ')}
                </div>
              )}
            </>
          ))}
        </div>
      );
    }
  };
  return <>{handleRenderData(data)}</>;
};
