import LoadingIcon from '@/public/icons/ic-loading.gif';
import Image from 'next/image';
import React from 'react';

function Button({
  classNames,
  title,
  icon,
  customIcon,
  isLoading = false,
  total,
  disabled = false,
  type = 'submit',
  tooltip = '',
  onClick,
  ...rest
}) {
  return (
    <button
      className={classNames}
      disabled={isLoading || disabled}
      type={type}
      title={tooltip}
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              onClick(e);
            }
          : () => {}
      }
      {...rest}
    >
      {isLoading && (
        <div className="loading">
          <Image src={LoadingIcon} alt="loading-icon" />
        </div>
      )}
      {customIcon && <div className="icon">{customIcon}</div>}
      {icon && (
        <div className="icon">
          <img src={icon?.src} alt="btn-icon" />
        </div>
      )}
      {title && (
        <div className="btn-title">
          {title} {total && `(${total})`}
        </div>
      )}
    </button>
  );
}

export default Button;

export function ButtonPrimary({ context, isLoading, ...props }) {
  return (
    <button {...props} className={`btn btn-primary ${props.className}`}>
      {context}
    </button>
  );
}
