import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import Select, { components } from 'react-select';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#333333',
    fontWeight: state.isSelected ? 600 : 400,
    backgroundColor: state.isSelected ? '#00cccb' : '#ffffff',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
  }),
  input: (styles) => ({ ...styles, ...optionSelected() }),
  singleValue: (styles, { data }) => ({ ...styles, ...optionSelected(data) }),
};

const customStyles2 = {
  control: (base) => ({
    ...base,
    width: '100%',
    minWidth: '200px', // Minimum width for smaller screens
    boxShadow: 'none',
  }),
  menu: (base) => ({
    ...base,
    zIndex: 999, // Ensure dropdown appears above other elements
  }),
};

function SelectCustom({
  isMulti = false,
  options = [],
  disabled,
  classNames = '',
  value = '',
  placeholder = '',
  hasError,
  onChange,
  ...rest
}) {
  return (
    <Select
      value={_.find(options, (opt) => opt.value === value)}
      customStyles={customStyles}
      isSearchable={false}
      isMulti={isMulti}
      isClearable={false}
      closeMenuOnSelect={isMulti ? false : true}
      className={` ${classNames} ${
        hasError ? 'custom-selection__invalid' : ''
      }`}
      classNamePrefix="custom-selection"
      placeholder={placeholder}
      options={options}
      isDisabled={disabled}
      menuPlacement="auto"
      components={{ DropdownIndicator }}
      onChange={onChange}
      {...rest}
    />
  );
}

function SelectMuslim({ options, muslimSelected, value }) {
  const optionSelected = options.map((option) => {
    return {
      value: option,
      label: option,
    };
  });
  const valueSelected = value.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
  const handleChange = (value) => {
    const selected = value.map((item) => item.value);
    muslimSelected(selected);
  };

  return (
    <Select
      isMulti
      options={optionSelected}
      value={valueSelected}
      classNamePrefix="select"
      onChange={handleChange}
      styles={{
        control: (base) => ({
          ...base,
          border: '1px solid #e4e4e4',
          borderRadius: '5px',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #00cccb',
          },
          minWidth: '200px',
          maxWidth: '800px',
        }),
        menu: (base) => ({
          ...base,
          zIndex: 1001,
        }),
      }}
    />
  );
}

function SelectSubCat({ options, subCatSelected, value }) {
  const handleChange = (value) => {
    subCatSelected(value);
  };

  return (
    <Select
      isMulti
      options={options}
      value={value}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={handleChange}
      styles={{
        control: (base) => ({
          ...base,
          border: '1px solid #e4e4e4',
          borderRadius: '5px',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #00cccb',
          },
          minWidth: '200px',
          maxWidth: '800px',
        }),
      }}
    />
  );
}

const DynamicSelectComponent = memo(function DynamicSelectComponent({
  options,
  value,
  defaultValue,
  onChange,
  placeholder,
  disabled,
  isMulti,
  className,
}) {
  const [selected, setSelected] = useState(value);
  useEffect(() => {
    setSelected(value);
  }, [value]);
  return (
    <Select
      className={className}
      value={selected}
      options={options}
      defaultValue={defaultValue}
      onChange={(valueChange) => {
        setSelected(valueChange);
        onChange(valueChange);
      }}
      placeholder={placeholder}
      disabled={disabled}
      isMulti={isMulti}
      classNamePrefix="select"
      styles={{
        control: (base) => ({
          ...base,
          border: '1px solid #e4e4e4',
          borderRadius: '5px',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #00cccb',
          },
          minWidth: '100%',
          maxWidth: '100%',
        }),
        option: (base, state) => ({
          ...base,
          fontWeight: 400,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#00cccb',
            color: '#ffffff',
          },
          backgroundColor: state.isSelected ? '#00cccb' : '#ffffff',
        }),
        menu: (base) => ({
          ...base,
          zIndex: 1010, // Ensure dropdown appears above other elements
        }),
      }}
    />
  );
});

export { SelectMuslim, SelectSubCat, DynamicSelectComponent };

export default SelectCustom;
